import React from 'react'

function ContactBar() {
	return (
		<footer className='contact-bar'>
			<span className='contact-bar-title'> Get In Touch </span>
			<div className='contact-info-container'>
				<span className='contact-option'>
					<i className='fa-regular fa-envelope fa-xl'></i>
					Info@GrindstoneRecruiting.ca
				</span>
				{/* <span className='contact-option'>
					<i className='fa-solid fa-phone fa-lg'></i> 519-123-4567
				</span> */}
			</div>
		</footer>
	)
}

export default ContactBar
