import { useEffect } from 'react'
import ContactBar from './components/ContactBar'
import Logo from './images/grindstone-whitetext-logo-nobg.png'
import './App.css'

function calculateInnerHeight() {
	let vh = window.innerHeight * 0.01
	document.documentElement.style.setProperty('--vh', `${vh}px`)
}

function animateElement(element) {
	element.classList.add('text-reveal-animation')
}

function App() {
	useEffect(() => {
		calculateInnerHeight()

		window.addEventListener('resize', calculateInnerHeight)
		// window.addEventListener('scroll', animateIfOversecting)

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					animateElement(entry.target)
				}
			})
		})

		const elements = document.querySelectorAll('.page-title, .text, li')
		elements.forEach((el) => observer.observe(el))

		return () => {
			window.removeEventListener('resize', calculateInnerHeight)
			// window.removeEventListener('scroll', animateIfOversecting)
		}
	}, [])

	return (
		<div className='App'>
			<main className='page' id='home'>
				<h1 className='title'>Headhunters for the Real Estate Industry</h1>
				<h3 className='sub-title'>
					Our firm brings over <b>20 years</b> of experience recruiting specifically for the
					<b> real estate industry</b>.
				</h3>
				<span className='help-text'> scroll to see more </span>
			</main>
			<div id='our-work-page-wrapper'>
				<section className='page' id='our-work-page'>
					<h2 className='page-title'>Our Work</h2>
					<p className='text'>
						With a client list that spans start-ups and entrepreneurs to institutional investors, we have placed skilled
						professionals across acquisition, development construction project management, asset management, property
						operations, and real estate finance roles.
					</p>
				</section>
			</div>
			<div id='bring-to-table-page-wrapper'>
				<section className='page' id='bring-to-table-page'>
					<h2 className='page-title'>What We Bring To The Table</h2>
					<ul>
						<li>A broad and deep network of contacts across all sectors of the industry</li>
						<li>Understanding of the relevant issues and trends affecting our clients</li>
						<li>Insight into functional roles beyond the “key words”</li>
						<li>
							Proprietary testing to assess Analyst level skills with <a href='https://www.btrhire.ca'>BTRHire</a>
						</li>
					</ul>
				</section>
			</div>
			<section className='page' id='inclusive-hiring-practices-page'>
				<h2 className='page-title'>Inclusive Hiring Practices</h2>
				<p className='text'>
					We help you broaden your candidate pool and access the relevant experience by targeting and considering professionals
					across all communities. We dive into the details of an individual’s foreign experience to understand how their skills
					translate, and bolster our understanding of their capabilities through references and testing.
				</p>
			</section>
			<ContactBar />
			<header>
				<img src={Logo} alt='' className='navbar-logo' />
			</header>
		</div>
	)
}

export default App
